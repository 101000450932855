import * as Types from 'Shared/models/WebsiteModel';
import { Website } from '../Actions/EnumTypes/ActionEnumTypes';
import { REQUEST_STATUS } from 'Shared/enums';
export type State = Types.WebsiteStore;

export const initialState: State = {
  dashboardUserWebsites: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  dashboardPartnerWebsites: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  dashboardWebsitesAmounts: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  dashboardWebsitesActions: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  dashboardPartnersInfo: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  dashboardPartnersAmounts: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  dashboardPartnersActions: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  websiteAbout: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  websiteSettings: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  websiteStatistics: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  websiteActions: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  clearSingleWebsiteState: {
    data: null,
  },
  partnersWebsites: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  allWebsite: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  allWebsiteCounts: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  pings: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  errors: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  webinar: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  reportData: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  diagnosticData: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    isLoader: false,
    openedDiagnostic: null,
  },
  checkWebsite: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  checkerList: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  wpUsers: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  wpUpdates: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  additionalUrls: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  resources: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
};

export function websiteReducer(state: any = initialState, action) {
  switch (action.type) {
    case Website.GET_PARTNERS_WEBSITES_INIT: {
      return {
        ...state,
        partnersWebsites: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_PARTNERS_WEBSITES_SUCCESS: {
      return {
        ...state,
        partnersWebsites: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_PARTNERS_WEBSITES_FAILURE: {
      return {
        ...state,
        partnersWebsites: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_DASHBOARD_USER_WEBSITES_INIT: {
      return {
        ...state,
        dashboardUserWebsites: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_DASHBOARD_PARTNER_WEBSITES_INIT: {
      return {
        ...state,
        dashboardPartnerWebsites: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_DASHBOARD_USER_WEBSITES_SUCCESS: {
      return {
        ...state,
        dashboardUserWebsites: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_DASHBOARD_PARTNER_WEBSITES_SUCCESS: {
      return {
        ...state,
        dashboardPartnerWebsites: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }

    case Website.GET_DASHBOARD_CLIENTS_WEBSITES_SUCCESS: {
      return {
        ...state,
        dashboardUserWebsites: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_DASHBOARD_USER_WEBSITES_FAILURE: {
      return {
        ...state,
        dashboardUserWebsites: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_DASHBOARD_PARTNER_ACTIONS_SUCCESS: {
      return {
        ...state,
        dashboardPartnersActions: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_DASHBOARD_PARTNER_ACTIONS_FAILURE: {
      return {
        ...state,
        dashboardPartnersActions: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_DASHBOARD_PARTNER_AMOUNTS_SUCCESS: {
      return {
        ...state,
        dashboardPartnersAmounts: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_DASHBOARD_PARTNER_AMOUNTS_FAILURE: {
      return {
        ...state,
        dashboardPartnersAmounts: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_DASHBOARD_WEBSITES_AMOUNTS_INIT: {
      return {
        ...state,
        dashboardWebsitesAmounts: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_DASHBOARD_WEBSITES_AMOUNTS_SUCCESS: {
      return {
        ...state,
        dashboardWebsitesAmounts: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_DASHBOARD_CLIENTS_WEBSITES_AMOUNTS_SUCCESS: {
      return {
        ...state,
        dashboardWebsitesAmounts: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_DASHBOARD_WEBSITES_AMOUNTS_FAILURE: {
      return {
        ...state,
        dashboardWebsitesAmounts: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_DASHBOARD_CLIENTS_WEBSITES_AMOUNTS_FAILURE: {
      return {
        ...state,
        dashboardWebsitesAmounts: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_DASHBOARD_WEBSITES_ACTIONS_INIT: {
      return {
        ...state,
        dashboardWebsitesActions: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_DASHBOARD_WEBSITES_ACTIONS_SUCCESS: {
      return {
        ...state,
        dashboardWebsitesActions: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_DASHBOARD_WEBSITES_ACTIONS_FAILURE: {
      return {
        ...state,
        dashboardWebsitesActions: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_DASHBOARD_PARTNERS_INFO_INIT: {
      return {
        ...state,
        dashboardPartnersInfo: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_DASHBOARD_PARTNERS_INFO_SUCCESS: {
      return {
        ...state,
        dashboardPartnersInfo: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_DASHBOARD_PARTNERS_INFO_FAILURE: {
      return {
        ...state,
        dashboardPartnersInfo: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_ABOUT_SINGLE_WEBSITE_INIT: {
      return {
        ...state,
        websiteAbout: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_ABOUT_SINGLE_WEBSITE_SUCCESS: {
      return {
        ...state,
        websiteAbout: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_ABOUT_SINGLE_WEBSITE_FAILURE: {
      return {
        ...state,
        websiteAbout: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_SINGLE_WEBSITE_SETTINGS_INIT: {
      return {
        ...state,
        websiteSettings: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_SINGLE_WEBSITE_SETTINGS_SUCCESS: {
      return {
        ...state,
        websiteSettings: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_SINGLE_WEBSITE_SETTINGS_FAILURE: {
      return {
        ...state,
        websiteSettings: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_SINGLE_WEBSITE_STATISTICS_INIT: {
      return {
        ...state,
        websiteStatistics: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_SINGLE_WEBSITE_STATISTICS_SUCCESS: {
      return {
        ...state,
        websiteStatistics: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_SINGLE_WEBSITE_STATISTICS_FAILURE: {
      return {
        ...state,
        websiteStatistics: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_SINGLE_WEBSITE_ACTIONS_INIT: {
      return {
        ...state,
        websiteActions: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_SINGLE_WEBSITE_ACTIONS_SUCCESS: {
      return {
        ...state,
        websiteActions: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_SINGLE_WEBSITE_ACTIONS_FAILURE: {
      return {
        ...state,
        websiteActions: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.CLEAR_SINGLE_WEBSITE_STATE_SUCCESS: {
      return {
        ...state,
        websiteAbout: {
          data: undefined,
          status: REQUEST_STATUS.IDLE,
        },
        websiteSettings: {
          data: undefined,
          status: REQUEST_STATUS.IDLE,
        },
        websiteStatistics: {
          data: undefined,
          status: REQUEST_STATUS.IDLE,
        },
        websiteActions: {
          data: undefined,
          status: REQUEST_STATUS.IDLE,
        },
      };
    }
    case Website.GET_WEBSITES_ALL_INIT: {
      return {
        ...state,
        allWebsite: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_WEBSITES_ALL_SUCCESS: {
      return {
        ...state,
        allWebsite: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }

    case Website.GET_WEBSITES_ALL_FAILURE: {
      return {
        ...state,
        allWebsite: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }

    case Website.GET_WEBSITES_ALL_COUNTS_INIT: {
      return {
        ...state,
        allWebsiteCounts: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_WEBSITES_ALL_COUNTS_SUCCESS: {
      return {
        ...state,
        allWebsiteCounts: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }

    case Website.GET_WEBSITES_ALL_COUNTS_FAILURE: {
      return {
        ...state,
        allWebsiteCounts: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }

    case Website.GET_CUSTOM_PINGS_INIT: {
      return {
        ...state,
        pings: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_CUSTOM_PINGS_SUCCESS: {
      const pings = action.payload.data;
      const keys = Object.keys(pings);
      const newKeys = keys.map((key) => {
        const date = new Date(key);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
      });

      const newData = {};
      newKeys.forEach((key, index) => {
        newData[key] = pings[keys[index]];
      });

      return {
        ...state,
        pings: {
          data: newData,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_CUSTOM_PINGS_FAILURE: {
      return {
        ...state,
        pings: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.CLEAR_CUSTOM_PINGS_SUCCESS: {
      return {
        ...state,
        pings: {
          data: undefined,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_ERROR_DETAILS_INIT: {
      return {
        ...state,
        errors: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_ERROR_DETAILS_SUCCESS: {
      return {
        ...state,
        errors: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_ERROR_DETAILS_FAILURE: {
      return {
        ...state,
        errors: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_WEBINAR_DETAILS_INIT: {
      return {
        ...state,
        webinar: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_WEBINAR_DETAILS_SUCCESS: {
      return {
        ...state,
        webinar: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_WEBINAR_DETAILS_FAILURE: {
      return {
        ...state,
        webinar: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_REPORT_DATA_INIT: {
      return {
        ...state,
        reportData: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_REPORT_DATA_SUCCESS: {
      return {
        ...state,
        reportData: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_REPORT_DATA_FAILURE: {
      return {
        ...state,
        reportData: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_DIAGNOSTIC_DATA_INIT: {
      return {
        ...state,
        diagnosticData: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
          openedDiagnostic: action.payload.uuid,
        },
      };
    }
    case Website.RUN_DIAGNOSTIC_INIT: {
      return {
        ...state,
        diagnosticData: {
          ...state.diagnosticData,
          data: undefined,
          status: REQUEST_STATUS.INIT,
          isLoader: true,
        },
      };
    }
    case Website.GET_DIAGNOSTIC_DATA_SUCCESS: {
      const payloadData = action.payload.data;
      const isOnlyUpdate = action.payload.isOnlyUpdate;
      const openedDiagnostic = action.payload?.openedDiagnostic;
      const newAllWebsiteData = state.allWebsite.data.map((website) => {
        if (website.id === payloadData.diagnostic.website_id) {
          let isNewDiagnostic = true;
          const newDiagnosticData = website?.diagnostics?.length
            ? website.diagnostics.map((diagnostic) => {
                if (diagnostic.id === payloadData.diagnostic.id) {
                  isNewDiagnostic = false;
                  return payloadData.diagnostic;
                }
                return diagnostic;
              })
            : [];
          if (isNewDiagnostic) {
            newDiagnosticData.unshift(payloadData.diagnostic);
          }
          return { ...website, diagnostics: newDiagnosticData };
        }
        return website;
      });

      if (isOnlyUpdate && state.diagnosticData.openedDiagnostic !== openedDiagnostic) {
        return {
          ...state,
          allWebsite: {
            ...state.allWebsite,
            data: newAllWebsiteData,
          },
        };
      }

      return {
        ...state,
        allWebsite: {
          ...state.allWebsite,
          data: newAllWebsiteData,
        },
        diagnosticData: {
          ...state.diagnosticData,
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
          isLoader: false,
        },
      };
    }
    case Website.GET_DIAGNOSTIC_DATA_FAILURE: {
      return {
        ...state,
        diagnosticData: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.CHECK_WEBSITE_INIT: {
      return {
        ...state,
        checkWebsite: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.CHECK_WEBSITE_SUCCESS: {
      return {
        ...state,
        checkWebsite: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.CHECK_WEBSITE_FAILURE: {
      return {
        ...state,
        checkWebsite: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.CHECKER_LIST_SUCCESS: {
      return {
        ...state,
        checkerList: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.CHECKER_LIST_FAILURE: {
      return {
        ...state,
        checkerList: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.UPDATE_CHECKER_SUCCESS: {
      const { id, phone_number } = action.payload.data;
      const checkerList = state.checkerList.data;
      const checker = checkerList?.data.find((checker) => checker.id === id);
      checker.phone_number = phone_number;

      return {
        ...state,
        checkerList: {
          data: checkerList,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_WP_USERS_INIT: {
      return {
        ...state,
        wpUsers: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_WP_USERS_SUCCESS: {
      const data = action.payload.data.map((user) => {
        const modifiedUser = {
          ...user,
          isActive: user.phone_number ? true : false,
        };

        return modifiedUser;
      });
      return {
        ...state,
        wpUsers: {
          data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_WP_USERS_FAILURE: {
      return {
        ...state,
        wpUsers: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.EDIT_WP_USERS_SUCCESS: {
      return {
        ...state,
        websiteAbout: {
          data: { ...state.websiteAbout.data, is_2fa_numbers_customized: action.payload.isNumbersCustomized },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.EDIT_WP_USERS_FAILURE: {
      return {
        ...state,
        wpUsers: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.MARK_WP_USER_ACTIVE_SUCCESS: {
      return {
        ...state,
      };
    }
    case Website.UPDATE_WEBSITE_CONTACT_NUMBER_SUCCESS: {
      return {
        ...state,
        websiteAbout: {
          data: {
            ...state.websiteAbout.data,
            phone_prefix: action.payload.data.phone_prefix,
            phone_number: action.payload.data.phone_number,
          },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_WORDPRESS_AVAILABLE_UPDATES_INIT: {
      return {
        ...state,
        wpUpdates: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_WORDPRESS_AVAILABLE_UPDATES_SUCCESS: {
      return {
        ...state,
        wpUpdates: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_WORDPRESS_AVAILABLE_UPDATES_FAILURE: {
      return {
        ...state,
        wpUpdates: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_WORDPRESS_AVAILABLE_UPDATES_INIT: {
      return {
        ...state,
        wpUpdates: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Website.GET_WORDPRESS_AVAILABLE_UPDATES_SUCCESS: {
      return {
        ...state,
        wpUpdates: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_WORDPRESS_AVAILABLE_UPDATES_FAILURE: {
      return {
        ...state,
        wpUpdates: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_ADDITIONAL_URLS_SUCCESS: {
      return {
        ...state,
        additionalUrls: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_ADDITIONAL_URLS_FAILURE: {
      return {
        ...state,
        additionalUrls: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Website.GET_RESOURCES_LIST_SUCCESS: {
      return {
        ...state,
        resources: {
          data: { ...state.resources.data, ...action.payload.data },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Website.GET_RESOURCES_LIST_FAILURE: {
      return {
        ...state,
        resources: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default: {
      return state;
    }
  }
}
